import React, { useState, useEffect } from "react";
import classes from "./JobPostingDetail.module.css";
import { GrImage, GrList, GrZoomIn, GrZoomOut } from "react-icons/gr";
import { AiOutlineCloseCircle, AiOutlineClose } from "react-icons/ai";
import AdsEntry from "../Component/AdsEntry";
import Heading from "../Component/Heading";
import DashboardButton from "../Component/DashboardButton";
import { useParams, useHistory } from "react-router-dom";
import AdminLoader from "../Component/AdminLoader";
import { curr } from "../Component/Currency";
import { states } from "../Component/States";

const JobPostingDetail = (props) => {
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [company, setCompany] = useState([]);
  const [jobDesc, setJobDesc] = useState();
  const [posTitle, setPosTitle] = useState();
  const [jobAppDetails, setJobAppDetails] = useState("");
  const [url, setUrl] = useState("");
  const [jobFunction, setJobFunction] = useState([]);
  const [selectedJobFunction, setSelectedJobFunction] = useState([]);
  const [adDetailImage, setAdDetailImage] = useState();
  const [location, setLocation] = useState();
  const [levelName, setLevelName] = useState([]);
  const [selectedLevelName, setSelectedLevelName] = useState();
  const [selectedLocationId, setSelectedLocationId] = useState([]);
  const [selectedLocationName, setSelectedLocationName] = useState([]);
  const [adId, setadId] = useState();
  const [isViewNextPositionClicked, setIsViewNextPositionClicked] = useState(false);
  const [employmentType, setEmploymentType] = useState();
  const [postalCode, setPostalCode] = useState();
  const [street, setStreet] = useState();
  const [currency, setCurrency] = useState();
  const [region, setRegion] = useState();
  const [minSal, setMinSal] = useState();
  const [maxSal, setMaxSal] = useState();
  const [positionTitle, setPositionTitle] = useState(false);
  const [prevId, setPrevId] = useState();
  const [prevPos, setPrevPos] = useState();
  const [prevDesc, setPrevDesc] = useState()
  const [prevMinSal, setPrevMinSal] = useState()
  const [prevMaxSal, setPrevMaxSal] = useState()
  const [prevEmail, setPrevEmail] = useState()
  const [prevEmpType, setPrevEmpType] = useState()
  const [prevCurrency, setPrevCurrency] = useState()
  const [prevStreet, setPrevStreet] = useState()
  const [prevRegion, setPrevRegion] = useState()
  const [prevUrl, setPrevUrl] = useState()
  const [prevPostalCode, setPrevPostalCode] = useState()


  const { id } = useParams();
  const { companyID } = useParams();

  let history = useHistory();

  useEffect(() => {
    adDetailsApi();
    selectionDetailsApi();
    setadId(id);
  }, [companyID]);

  const employment = [
    {
      type: "Full-Time",
      name: "FULL_TIME"
    },
    {
      type: "Part-Time",
      name: "PART_TIME"
    },
    {
      type: "Contract",
      name: "CONTRACTOR"
    },
    {
      type: "Temporary",
      name: "TEMPORARY"
    },
    {
      type: "Paid per day",
      name: "PER_DIEM"
    },
    {
      type: "Internship",
      name: "INTERN"
    },
  ];


  const back = () => {
    history.push("/BiDashboard/Dashboard");
  };

  const previousPosition = () => {
    history.push("/BiDashboard/JobPostingDetail/" + prevId + "/" + companyID);
    window.location.reload();

  };

  const findLocationIdHandler = (locationName) => {
    location.find((val) => {
      if (locationName === val.locName) {
        setSelectedLocationId((preLocationId) => {
          return [...preLocationId, val.id];
        });
      }
    });
  };

  //add function for list of locations

  const addLocationHandler = (selectedLocation) => {
    setSelectedLocationName((preLocation) => {
      return [...preLocation, selectedLocation];
    });
  };

  //delete function for delete location box

  const deleteLocationName = (id) => {
    setSelectedLocationName((preLocation) => {
      return preLocation.filter((item, index) => {
        return index !== id;
      });
    });
  };
  const deleteLocationId = (id) => {
    setSelectedLocationId((preLocationId) => {
      return preLocationId.filter((item, index) => {
        return index !== id;
      });
    });
  };

  //AD DETAILS API

  const adDetailsApi = () => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: id ? id : prevId,
    });

    var requestOptions = {
      body: raw,
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch("https://api.onecorp.co.in/adminapi/ad-details", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        setAdDetailImage(result.data.imageUrl);
        setJobAppDetails(result.data.applicationDetails);
        setPrevId(result.data.id);
        setPrevPos(result.data.positionTitle)
        setPrevDesc(result.data.jobDescription)
        setPrevMinSal(result.data.minCtc)
        setPrevMaxSal(result.data.maxCtc)
        setPrevCurrency(result.data.Currency)
        setPrevRegion(result.data.AddressRegion)
        setPrevUrl(result.data.externalApplyUrl)
        setPrevStreet(result.data.StreetAddress)
        setPrevPostalCode(result.data.PostalCode)
        setPrevEmail(result.data.email)
        setPrevEmpType(result.data.EmploymentType)
        setPrevStreet(result.data.StreetAddress)

      })
      .catch((error) => console.log("error", error));
  };
  //SAVE POSTING API

  const savePosting = () => {
    const locations = selectedLocationId.toString();

    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: adId ? adId : prevId,
      positionTitle: posTitle ? posTitle : prevPos,
      level: selectedLevelName,
      jobDescription: jobDesc ? jobDesc : prevDesc,
      email: email ? email : prevEmail,
      externalApplyUrl: url ? url : prevUrl,
      minExperience: "",
      maxExperience: "",
      governmentJob: "",
      companyId: companyID,
      applicationDetails: jobAppDetails,
      isWalkin: "",
      skills: "",
      isPublished: "",
      location: locations,
      jobCategory: selectedJobFunction,
      Currency: currency ? currency : prevCurrency,
      EmploymentType: employmentType ? employmentType : prevEmpType,
      AddressRegion: region ? region : prevRegion,
      StreetAddress: street ? street : prevStreet,
      PostalCode: postalCode ? postalCode : prevPostalCode,
      minCtc: minSal ? minSal : prevMinSal,
      maxCtc: maxSal ? maxSal : prevMaxSal,
    });

    console.log(raw);
    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch("https://api.onecorp.co.in/adminapi/update-printads", requestOptions)
      .then((response) => response.json())
      .then((result) => {
        console.log(result);

        if (result.status === "SUCCESS" && result.status_code === 200) {
          alert("Posting Saved");
          setPosTitle("");
          setPrevPos("");
          setEmail("");
          setPrevEmail("")
          setJobDesc("");
          setUrl("");
          setPrevUrl("")
          setStreet("")
          setPrevStreet("")
          setPostalCode("")
          setPrevPostalCode("")
          setCurrency("")
          setPrevCurrency("")
          setPrevDesc("")
          setPrevMinSal("")
          setPrevMaxSal("")

          setSelectedLocationName([]);
        } else {
          alert("Data not Saved");
        }
      })
      .catch((error) => console.log("error", error));
  };

  //SELECTION DETAILS API

  const selectionDetailsApi = (data) => {
    setLoading(true);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      redirect: "follow",
    };

    fetch(
      "https://api.onecorp.co.in/adminapi/selection-details",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        setLevelName(result.leveldata);
        setJobFunction(result.jobFunctiondata);
        setLocation(result.locationdata);
        setCompany(result.companydata);
      })
      .catch((error) => console.log("error", error))
      .finally(() => setLoading(false));
  };
  //SAVE AND VIEW NEXT POS API

  const saveAndViewNextPos = () => {
    setPositionTitle(false);
    var myHeaders = new Headers();
    myHeaders.append(
      "Authorization",
      "Token 77927b69bb144b065ca11bf2a9d452819cd852db"
    );
    myHeaders.append("Content-Type", "application/json");

    var raw = JSON.stringify({
      id: adId ? adId : prevId,
      positionTitle: posTitle ? posTitle : prevPos,
      level: selectedLevelName,
      jobDescription: jobDesc ? jobDesc : prevDesc,
      email: email ? email : prevEmail,
      externalApplyUrl: url ? url : prevUrl,
      governmentJob: "",
      companyId: companyID,
      location: selectedLocationId.toString(),
      jobRefCode: "",
      vacancies: "",
      companyDetails: "",
      minExperience: "",
      maxExperience: "",
      applicationDetails: jobAppDetails,
      isWalkin: "",
      skills: "",
      isPublished: "0",
      imageUrl: adDetailImage,
      Currency: currency ? currency : prevCurrency,
      EmploymentType: employmentType ? employmentType : prevEmpType,
      AddressRegion: region ? region : prevRegion,
      StreetAddress: street ? street : prevStreet,
      PostalCode: postalCode ? postalCode : prevPostalCode,
      minCtc: minSal ? minSal : prevMinSal,
      maxCtc: maxSal ? maxSal : prevMaxSal,
    });

    var requestOptions = {
      method: "POST",
      headers: myHeaders,
      body: raw,
      redirect: "follow",
    };

    fetch(
      "https://api.onecorp.co.in/adminapi/update-printads-next-position",
      requestOptions
    )
      .then((response) => response.json())
      .then((result) => {
        console.log(result);
        if (result.status === "SUCCESS" && result.status_code === 200) {
          history.push(
            "/BiDashboard/JobPostingDetail/" + result.data + "/" + companyID
          );
          alert("New Position Created")
          setPositionTitle(true);
          setPositionTitle(result.Title);
          setIsViewNextPositionClicked(true);
          setPosTitle("");
          setPrevPos("");
          setEmail("");
          setPrevEmail("")
          setJobDesc("");
          setUrl("");
          setPrevUrl("")
          setStreet("")
          setPrevStreet("")
          setPostalCode("")
          setPrevPostalCode("")
          setCurrency("")
          setPrevCurrency("")
          setPrevDesc("")
          setPrevMinSal("")
          setPrevMaxSal("")


          setSelectedLocationName([]);
        } else {
          alert("Please fill the required field");
        }
      })
      .catch((error) => console.log("error", error));
  };

  return (
    <div className={classes.jobAds}>
      <div className={classes.jobAdsImageField}>
        <div className={classes.jobAdsIcons}>
          <GrZoomIn className={classes.icon} />
          <GrZoomOut className={classes.icon} />
          <GrImage className={classes.icon} />
          <GrList className={classes.icon} />
          <AiOutlineCloseCircle className={classes.icon} />
        </div>
        <div className={classes.jobAdsImage}>
          <img src={adDetailImage} alt="jobAdsImage" />
        </div>
      </div>

      {loading ? (
        <div className={classes.loadingLayout}>
          {" "}
          <AdminLoader />{" "}
        </div>
      ) : (
        <div className={classes.JobAdsEntry}>
          <Heading
            headingtype={"BgHeading"}
            headingtext={"Job Posting Details"}
          />
          <div className={classes.adEntry}>

            {!isViewNextPositionClicked && (
              <AdsEntry
                input={"MultiLineInput"}
                label={"Job Application Details"}
                onChange={(e) => setJobAppDetails(e.target.value)}
                value={jobAppDetails}

              />
            )}

            <AdsEntry
              input={"SingleLineInput"}
              label={"Position Title"}
              onChange={(e) => setPosTitle(e.target.value)}
              value={posTitle ? posTitle : prevPos}
              text={"*"}

            />
            <AdsEntry
              input={"smalldropdown"}
              label={"Job Function"}
              displayData={jobFunction}
              onChange={(e) => setSelectedJobFunction(e.target.value)}
              text={"*"}
            />
            <AdsEntry
              input={"smalldropdown"}
              label={"Level"}
              displayData={levelName}
              onChange={(e) => setSelectedLevelName(e.target.value)}
              text={"*"}
            />
            <AdsEntry
              input={"MultiLineInput"}
              label={"Job Description"}
              value={jobDesc ? jobDesc : prevDesc}
              onChange={(e) => setJobDesc(e.target.value)}
              text={"*"}
            />
            <AdsEntry
              input={"SingleLineInput"}
              label={"MinSalary"}
              onChange={(e) => setMinSal(e.target.value)}
              value={minSal ? minSal : prevMinSal}
              text={"*"}
            />
            <AdsEntry
              input={"SingleLineInput"}
              label={"MaxSalary"}
              onChange={(e) => setMaxSal(e.target.value)}
              value={maxSal ? maxSal : prevMaxSal}
              text={"*"}
            />
            <AdsEntry
              input={"email"}
              label={"Email"}
              onChange={(e) => {
                setEmail(e.target.value);
              }}
              value={email ? email : prevEmail}
            />
            <AdsEntry
              input={"largedropdown"}
              label={"Employment Type"}
              onChange={(e) => setEmploymentType(e.target.value)}
              displayData={employment}
              defaultEmpType={prevEmpType}
              text={"*"}
            />
            <AdsEntry
              input={"SingleLineInput"}
              label={"Postal Code"}
              onChange={(e) => setPostalCode(e.target.value)}
              value={postalCode ? postalCode : prevPostalCode}
              text={"*"}
            />
            <AdsEntry
              input={"largedropdown"}
              label={"Currency"}
              onChange={(e) => setCurrency(e.target.value)}
              displayData={curr}
              defaultCurrency={prevCurrency}
            />
            <AdsEntry
              input={"SingleLineInput"}
              label={"Street Address"}
              onChange={(e) => setStreet(e.target.value)}
              value={street ? street : prevStreet}
            />
            <AdsEntry
              input={"largedropdown"}
              label={"Region"}
              onChange={(e) => setRegion(e.target.value)}
              displayData={states}
              defaultRegion={prevRegion}
              text={"*"}
            />
            <AdsEntry
              input={"SingleLineInput"}
              label={"External Apply URL"}
              value={url ? url : prevUrl}
              onChange={(e) => setUrl(e.target.value)}
            />
            <p className={classes.text}>
              (Please enter external URL from print ads if email is not present)
            </p>

            {/* mapping of selected location part  */}

            <div className={classes.locationBoxWrapper}>
              {selectedLocationName.map((currentLocationValue, index) => {
                return (
                  <div key={index} id={index} className={classes.locationBox}>
                    {currentLocationValue}
                    <AiOutlineClose
                      onClick={() => {
                        deleteLocationName(index);
                        deleteLocationId(index);
                      }}
                      className={classes.closeIcon}
                    />
                  </div>
                );
              })}
            </div>
            <AdsEntry
              input={"largedropdown"}
              label={"Locations"}
              displayData={location}
              onChange={(e) => {
                findLocationIdHandler(e.target.value);
                addLocationHandler(e.target.value);
              }}
              text={"*"}
            />

            {/* <AdsEntry input={'SingleLineInput'} label={"Skills"} value = {skills}/> */}
            <div className={classes.walkInDetails}>
              <Heading
                headingtype={"SimpleHeading"}
                headingtext={"Walk in Details"}
              />
              <AdsEntry input={"DateInput"} label={"Start Date"} />
              <AdsEntry input={"DateInput"} label={"End Date"} />
              {/* <AdsEntry input={"SingleLineInput"} label={"Location"} /> */}
              <AdsEntry input={"MultiLineInput"} label={"Address"} />
            </div>
            <div className={classes.walkInInterviewButtons}>
              <DashboardButton
                buttontext={"View Next Position"}
                onClick={() => saveAndViewNextPos()}
              />
              <DashboardButton buttontext={"Back"} onClick={() => back()} />
              <DashboardButton
                buttontext={"Save Posting"}
                onClick={() => savePosting()}
              />
              <DashboardButton
                buttontext={"Previous Position"}
                onClick={() => previousPosition()}
              />
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default JobPostingDetail;
