import React from "react";
import classes from "./EventInputs.module.css";

const EventInputs = (props) => {
  const comp = () => {
    switch (props.label) {
      case "Course Type":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key={item.id}>{item.type}</option>;
              })}
          </>
        );

      case "Quiz Category":
        return (
          <>
            <option value="" disabled selected>
              Please select
            </option>
            {props.displayData.map((item) => (
              <option key={item.id} value={item.QuizCategory}>
                {item.QuizCategory}
              </option>
            ))}
          </>
        );

      case "Webstory Type":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key={id}>{item.type}</option>;
              })}
          </>
        );
      case "Course Group":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key={item.id}>{item.type}</option>;
              })}
          </>
        );
      case "Employment title":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return (
                  <option value={item.type} key={item.id}>
                    {item.type}
                  </option>
                );
              })}
          </>
        );
      case "Category":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key={item.id}>{item.type}</option>;
              })}
          </>
        );
      case "Offered Salary":
        return (
          <>
            {!!props.displayData &&
              props.displayData.map((item, id) => {
                return <option key={item.id}>{item.type}</option>;
              })}
          </>
        );
      case "Article Type":
        return (
          <>
            {" "}
            <option value="" disabled selected>
              Please select{" "}
            </option>
            {props.displayData.map((item, id) => {
              return (
                <>
                  <option key={item.id}>{item.type}</option>;
                </>
              );
            })}
          </>
        );
      case "Page Name":
        return (
          <>
            {" "}
            <option value="" disabled selected>
              Please select{" "}
            </option>
            {props.displayData.map((item, id) => {
              return (
                <>
                  <option key={item.id}>{item.type}</option>;
                </>
              );
            })}
          </>
        );
      case "Post type":
        return (
          <>
            <option value="" disabled selected>
              Please select
            </option>
            {props.displayData.map((item, id) => {
              return (
                <>
                  <option selected={item.type === props.value} key={item.id}>
                    {item.type}
                  </option>
                  ;
                </>
              );
            })}
          </>
        );
      default:
        break;
    }
  };

  const inputType = (type) => {
    switch (type) {
      case "SingleLineInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input">
                {props.label}
                {props.required && (
                  <span className={classes.required}>*</span>
                )}{" "}
              </label>
            </div>
            <div className={classes.fields1}>
              <input
                type="text"
                name={props.name}
                required={props.required}
                onChange={props.onChange}
                placeholder={props.placeholder}
                value={props.value}
                className={classes.inputField}
                autoComplete="off"
                maxLength={props.maxLength}
                disabled={props.disabled}
              />
            </div>
          </div>
        );
      case "email":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input2">{props.label} </label>
            </div>
            <div className={classes.fields1}>
              <input
                type="text"
                id="Email"
                name="Email"
                value={props.value}
                onChange={props.onChange}
                className={classes.inputField}
                placeholder={props.placeholder}
              />
            </div>
          </div>
        );
      case "MultiLineInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input3">{props.label} </label>
            </div>
            <div className={classes.fields2}>
              <textarea
                rows="10"
                cols="30"
                name="text"
                onChange={props.onChange}
                value={props.value}
                className={classes.multiLineTextBox}
                placeholder={props.placeholder}
              />
            </div>
          </div>
        );
      case "DateInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input3">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <input
                type="text"
                id="date"
                name="date"
                className={classes.inputField}
                onChange={props.onChange}
                onFocus={(e) => (e.target.type = "date")}
                placeholder={props.placeholder}
                value={props.value}
              />
            </div>
          </div>
        );
      case "TimeInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input5">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <input
                type="text"
                id="time"
                name="time"
                className={classes.inputField}
                onChange={props.onChange}
                onFocus={(e) => (e.target.type = "time")}
                placeholder={props.placeholder}
                value={props.value}
              />
            </div>
          </div>
        );
      case "Number":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input5">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <input
                type="number"
                id="number"
                name="number"
                className={classes.inputField}
                onChange={props.onChange}
                onFocus={(e) => (e.target.type = "number")}
                placeholder={props.placeholder}
                value={props.value}
              />
            </div>
          </div>
        );
      case "ImageInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input5">
                {props.label}
                {props.required && <span className={classes.required}>*</span>}
              </label>
            </div>
            <div className={classes.fields1}>
              <input
                type="file"
                id="input5"
                accept={props.accept ? props.accept : ".png ,.jpeg,.jpg ,.webp"}
                name="image"
                className={classes.inputField}
                onChange={props.onChange}
                required={props.required}
              />
            </div>
          </div>
        );
      case "JsonInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input5">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <input
                type="file"
                id="input5"
                accept=".json"
                name="image"
                className={classes.inputField}
                onChange={props.onChange}
                required={props.required}
              />
            </div>
          </div>
        );
      case "MultiImageInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input5">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <input
                type="file"
                id="input5"
                name="image"
                accept=".png ,.jpeg ,.webp"
                multiple
                className={classes.inputField}
                onChange={props.onChange}
              />
            </div>
          </div>
        );
      case "UpdateImageInput":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="input7">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <input
                type="file"
                id="input5"
                name="image"
                className={classes.inputField}
                onChange={props.onChange}
              />
            </div>
          </div>
        );

      case "typedropdown":
        return (
          <div className={props.classes.input}>
            <div className={props.classes.fieldName}>
              <label htmlFor="dropdown">{props.label}</label>
            </div>
            <div className={props.classes.fields1}>
              <select
                onChange={props.onChange}
                className={props.classes.dropdownInput}
                value={props.value}
              >
                <option value="">Select an option</option>
                <option value="Quiz">Quiz</option>
                <option value="Category">Category</option>
              </select>
            </div>
          </div>
        );
      case "SingleLineInput":
        return (
          <div className={props.classes.input}>
            <div className={props.classes.fieldName}>
              <label htmlFor="single-line-input">{props.label}</label>
            </div>
            <div className={props.classes.fields1}>
              <input
                type="text"
                id="single-line-input"
                placeholder={props.placeholder}
                onChange={props.onChange}
                value={props.value}
              />
            </div>
          </div>
        );
      case "CheckBox":
        return (
          <div className={classes.fieldName1}>
            <input
              type="Checkbox"
              className={classes.checkbox_input}
              onChange={props.onChange}
              checked={props.value}
              disabled={props.disabled}
              value={props.value}
            />
            <label htmlFor="input6">{props.label}</label>
          </div>
        );

      case "smalldropdown":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="dropdown">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <select
                onChange={props.onChange}
                className={classes.dropdownInput}
                value={props.value}
              >
                {comp()}
              </select>
            </div>
          </div>
        );

      case "regionDropdown":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label>
                {props.label}
                {/* <span>*</span> */}
              </label>
            </div>
            <div className={classes.fields1}>
              <select
                className={classes.dropdownInput}
                value={props.value || ""}
                onChange={props.onChange}
                required
              >
                <option value="" disabled>
                  Choose the option
                </option>
                {props.displayData.map((item) => (
                  <option key={item.code} value={item.name}>
                    {item.code}-{item.name}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );

      case "cityDropdown":
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label>{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <select
                className={classes.dropdownInput}
                value={props.value || ""}
                onChange={(e) => {
                  const selectedCity = e.target.value;
                  props.onChange(selectedCity); // Update city value
                }}
                required
              >
                <option value="" disabled>
                  Choose the city
                </option>
                {props.suggestions
                  .filter((city) =>
                    city.name.toLowerCase().includes(props.value.toLowerCase())
                  )
                  .map((item) => (
                    <option key={item.KeyIndex} value={item.name}>
                      {item.name}
                    </option>
                  ))}
              </select>
            </div>
          </div>
        );

      case "Select": // New Select case
        return (
          <div className={classes.input}>
            <div className={classes.fieldName}>
              <label htmlFor="dropdown">{props.label}</label>
            </div>
            <div className={classes.fields1}>
              <select
                onChange={props.onChange}
                className={classes.dropdownInput}
                value={props.value}
                required={props.required}
                name={props.name}
              >
                <option value="" disabled>
                  Select an option
                </option>
                {props.options.map((option, index) => (
                  <option key={index} value={option.value}>
                    {option.label}
                  </option>
                ))}
              </select>
            </div>
          </div>
        );
      default:
        console.log("Invalid Input");
        break;
    }
  };

  return <div>{inputType(props.input)}</div>;
};

export default EventInputs;
